rodijco.controller 'ConfigController', [
  '$scope'
  '$http'
  'httpService'
  (scope, http, httpService) ->

    scope.config = {}

    # get current configuration
    httpService.get 'config', (response) ->
      scope.config = response.data[0]
      scope.header_text = '0'

    scope.newCertificateText = () ->
      scope.config.cer.header_text.push { name: 'Nieuw' }
      scope.header_text = (scope.config.cer.header_text.length - 1).toString()

    scope.removeCertificateText = (headerText) ->
      scope.config.cer.header_text.splice headerText, 1
      scope.header_text = '0'

    scope.removeImage = (header_image) ->
      scope.$apply ->
        scope.config.cer.header_image.splice header_image, 1
        scope.save()

    scope.save = () ->
      httpService.post 'config', scope.config, (response) ->
        if response.success
          Materialize.toast 'Admin config opgeslagen', DIALOG_DURATION

    scope.uploadFile = (file) ->
      fd = new FormData()

      fd.append 'file', file[0]
      http.post('/api/config/upload', fd,
        withCredentials: true
        headers: 'Content-Type': undefined
        transformRequest: angular.identity)
      .success (response) ->
        if response?.success
          return location.reload()

        console.error response

]
